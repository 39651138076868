import { Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { Mail } from '@mui/icons-material'
import EmailExportButton from '../../EmailExportButton/EmailExportButton'

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          color: '#A1A1AA',
          '&:hover': {
            color: '#414A51 !important',
            backgroundColor: 'transparent'
          }
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          paddingRight: '10px !important'
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          fontWeight: 'bold',
          lineHeight: '20px',
          fontSize: '15px'
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          borderBottom: '1px solid #D4D4D8',
          color: '#414A51'
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          textAlign: 'end',
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#e4e4e7'
          }
        }
      }
    }
  },
});



function CalculationGrid({ data, columns }) {

  const customToolbar = () => {

    const emailButtonProps = {
      children: <Mail />
    }

    return <EmailExportButton emailButtonProps={emailButtonProps} columns={columns} rows={data} />
  }

  const options = {
    elevation: 0,
    filter: false,
    pagination: false,
    sort: false,
    search: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: true,
    download: true,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No calculation done.",
      }
    },
    customToolbar,
    // customHeadRender
  };

  return <>
    <div className='rounded border border-zinc-300 border-solid'>
      <ThemeProvider theme={theme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </ThemeProvider>
    </div>
    <Typography className='pt-1.5 text-xs italic text-right text-28east-gray'>*These figures show an estimated cost based on the volumes provided here and are not based on the actual usage of your account</Typography>
  </>
}

export default CalculationGrid;
