import { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalculationGrid from './CalculationGrid';


function CalculationGridPanel({ data, currentExpandedPanel, handleChange }) {

  const columns = [
    {
      name: 'productCategory',
      label: 'Product Category',
      options: {
        filter: true,
        sort: true,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                fontWeight: 'bold',
                color: '#fff'
              }
            }
          }
        },
      },
    },
    {
      name: 'productApi',
      label: 'Product API',
      options: {
        filter: true,
        sort: true,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff',
                fontWeight: 'bold'
              }
            }
          }
        },
      },
    },
    {
      name: 'usage',
      label: 'Usage',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff'
              }
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          if (value)
            return new Intl.NumberFormat().format(+value)
        }
      },
    },
    {
      name: 'googleDirectDollars',
      label: 'Google Direct ($)',
      // need for email sharing as a prefix
      currency: 'USD',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff'
              }
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-US", {
            style: 'currency',
            currency: 'USD', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
    {
      name: 'googleDirectRands',
      label: 'Google Direct (R)',
      // need for email sharing as a prefix
      currency: 'ZAR',
      options: {
        filter: true,
        sort: false,
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex > data.length - 2) {
            return {
              style: {
                backgroundColor: `#414A51`,
                color: '#fff'
              }
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-ZA", {
            style: 'currency',
            currency: 'ZAR', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
    {
      name: 'eastDollarDiscount',
      label: 'Cost With 28East Discount ($)',
      // need for email sharing as a prefix
      currency: 'USD',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({
          style: {
            color: '#E31B23'
          }
        }),
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex < data.length - 1) {
            return {
              style: {
                backgroundColor: `rgba(227, 27, 35, 0.2)`,
                fontWeight: 'bold',
              }
            }
          }
          return {
            style: {
              backgroundColor: `#E31B23`,
              fontWeight: 'bold',
              color: '#fff'
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-US", {
            style: 'currency',
            currency: 'USD', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
    {
      name: 'eastRandDiscount',
      label: 'Cost With 28East Discount (R)',
      // need for email sharing as a prefix
      currency: 'ZAR',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: value => ({
          style: {
            color: '#E31B23'
          }
        }),
        setCellProps: (value, rowIndex, columnIndex) => {
          if (rowIndex < data.length - 1) {
            return {
              style: {
                backgroundColor: `rgba(227, 27, 35, 0.2)`,
                fontWeight: 'bold',
              }
            }
          }
          return {
            style: {
              backgroundColor: `#E31B23`,
              fontWeight: 'bold',
              color: '#fff'
            }
          }
        },
        customBodyRender(value, tableMeta, updateValue) {
          const returnedCurrencyNumber = new Intl.NumberFormat("en-ZA", {
            style: 'currency',
            currency: 'ZAR', maximumFractionDigits: 0
          }).format(value)
          return returnedCurrencyNumber
        }
      },
    },
  ];

  return (
    <div>
      <Accordion expanded={currentExpandedPanel.calculationGrid} onChange={handleChange('calculationGrid')} elevation={0} variant="outlined" className='p-2 mb-8  border-zinc-300'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
          <div>
            <Typography className='text-xl font-bold text-28east-gray'>
              Estimated costs currently
            </Typography>
            <Typography className='text-base text-28east-gray'>
              The estimated costs including the 28East discount are displayed below.
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails >
          <CalculationGrid data={data} columns={columns} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default CalculationGridPanel