import NumberFormat from 'react-number-format';
import { TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme, makeStyles } from '@mui/material/styles';

// ? I'm adding this custom input component in here so that you can edit it as you please.
// ? You can override any of the normal things you can on the MaterialUI textfield
// ? The NumberFormat component handles the formatting of the input boxes but uses the MaterialReact TextField.
// ? You can still format it accordingly

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          textAlign: 'end !important'
        }
      }
    }
  }
})


// ! If you want to add a css file to customise this please create a folder to contain all the files related to this component

function CalculationInput({ text, mappedText, field, form: { isSubmitting }, internalKey, ...props }) {
  return <>
    <div>
      <Typography variant='h6' className='text-28east-gray'>{text}</Typography>
      {mappedText &&
        <Typography variant='caption' className='text-28east-gray'>**{mappedText}</Typography>
      }
    </div>
    <ThemeProvider theme={theme}>
      <NumberFormat key={internalKey} style={{ textAlign: 'end' }} thousandSeparator {...props} {...field} disabled={isSubmitting} variant="outlined" customInput={TextField} type='text' id={internalKey} className="mb-3 bg-white rounded" />
    </ThemeProvider>
  </>
}

export default CalculationInput;
